<template>
  <div class="top-donor-restaurants-container">
    <div v-if="showBanner" class="no-donations-banner">
      <h2>¡Aún no han comenzado las donaciones!</h2>
      <p>
        Mientras tanto, puedes explorar los restaurantes colaboradores en nuestra página. Descubre su compromiso con nuestra causa solidaria.
      </p>
      <a href="https://restaurantessolidarios.com/restaurantes-solidarios" class="visit-link">Ver restaurantes colaboradores</a>
    </div>
    <div v-else class="top-donor-restaurants">
      <h2 class="solidarity-title">Restaurantes más solidarios</h2>
      <div class="restaurant-cards">
        <RestaurantCard 
          v-for="(restaurant, index) in topDonors"
          :key="index"
          :imageSrc="restaurant.imageSrc || defaultImage"
          :name="restaurant.name || ''"
          :rank="restaurant.rank || ''"
          :class="getMedalClass(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RestaurantCard from './RestaurantCard.vue';
import defaultImage from '@/assets/example.jpg';

export default {
  components: {
    RestaurantCard
  },
  data() {
    return {
      topDonors: [
        { name: 'Restaurante A', rank: 1, imageSrc: defaultImage },
        { name: 'Restaurante B', rank: 2, imageSrc: defaultImage },
        { name: 'Restaurante C', rank: 3, imageSrc: defaultImage }
      ],
      defaultImage,
      showBanner: true // Se inicializa como true hasta verificar la fecha
    };
  },
  methods: {
    getMedalClass(index) {
      switch(index) {
        case 0:
          return 'gold';
        case 1:
          return 'silver';
        case 2:
          return 'bronze';
        default:
          return '';
      }
    },
    checkDate() {
      const currentDate = new Date();
      const cutoffDate = new Date('2025-06-10'); // Fecha límite

      // Mostrar los restaurantes o el banner basado en la fecha
      if (currentDate <= cutoffDate) {
        this.showBanner = true;
      } else {
        this.showBanner = false;
      }
    }
  },
  mounted() {
    this.checkDate();
  }
};
</script>

<style scoped>
.top-donor-restaurants-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Fondo suave */
  border-radius: 15px;
}

.no-donations-banner {
  text-align: center;
  padding: 20px;
  background-color: #ffffff; /* Fondo blanco */
  border: 2px solid #99d3df;
  border-radius: 10px;
}

.no-donations-banner h2 {
  color: #99d3df;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.no-donations-banner p {
  color: #333;
  font-size: 1.2em;
}

.visit-link {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #99d3df;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
}

.visit-link:hover {
  background-color: #77b9c7;
}

.top-donor-restaurants {
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.solidarity-title {
  margin-bottom: 30px;
  font-size: 1.8em;
  font-weight: bold;
  color: #333; /* Color del título */
}

.restaurant-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* Clases para los colores oro, plata y bronce */
.gold {
  background-color: #d4b500cb; /* Oro */
}

.silver {
  background-color: #c0c0c0; /* Plata */
}

.bronze {
  background-color: #cd7f32; /* Bronce */
}
</style>
